import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'



Vue.config.productionTip = false




// 引进全局注册组件
import top_nav from './components/top_nav'
import bottom from './components/bottom'
import AllSearch from "./components/AllSearch";
// 全局定义组件,
// Vue.use(mytable123)//及其错误的全局注册办法
// 关键 不能使用Vue.use()
Vue.component( 'top_nav', top_nav)//唯一正确的全局注册办法
Vue.component( 'bottom', bottom)//唯一正确的全局注册办法
Vue.component( 'AllSearch', AllSearch)//唯一正确的全局注册办法


// 验证码
// import Vue from 'vue';
import SlideVerify from 'vue-monoplasty-slide-verify';
 
Vue.use(SlideVerify);


import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

//  引入ElementUI
Vue.use(ElementUI);





new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
