import Vue from 'vue'
import Vuex from 'vuex'
import goodsList from './modules/goodsList'
import GoodsDetail from './modules/GoodsDetail'
import GoodsCart from './modules/GoodsCart'
import address from './modules/address'
import order from './modules/order'
import home from './modules/home'
import toast from './modules/toast'
import pageCode from './modules/pageCode'
import Pay from './modules/Pay'
import mypublic from './modules/mypublic'
import mine from './modules/mine'
import GoodsDetailPublic from './modules/GoodsDetailPublic'



Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    goodsList,
    GoodsCart,
    address,
    GoodsDetail,
    order,
    home,
    toast,
    pageCode,
    Pay,
    mypublic,
    mine,
    GoodsDetailPublic,
  }
})
