
import axios from 'axios'

// 商品详情页数据
// 定义一个商品列表模块
const GoodsDetailPublic = {
    // 模块命名空间
    namespaced: true,
    // 模块里面state必须是函数形式，
    state() {
        return{
          // 用户评价页面是否展示
          show_commentPage:false,


        }
    },
    getters: {
 
    },
    mutations: {
        //1、 该spu展示模板的数据
        set_show_template(state,value){
          // 展示模板赋值
          state.show_template = value 
      
        },

    




  
  },

   actions: {

      // 获取商品详情页主题图片和六张小图片
      async getDetailImage(context) {
        // action中使用state数据方法 context.state.spu_id
        // const spuID= context.state.spu_id       
        const spu_id= localStorage.getItem('home_spu_id')
        
        // console.log('刚刚');
        // console.log(spu_id);
          // 构造url字符串
          let url = "Home/GoodsSpuDetailImage/spu_detailpage_showList/?spu_id=" + spu_id;   
          let {data : res}= await axios.get(url)
          // console.log('齐鲁青未了');
          // console.log(res.data);
          if(res.code==200){
               // 给spuData赋值
               context.commit('setSpuImageList', res.data)

          }

        },

      
   },

    modules: {}
  }


export default GoodsDetailPublic;