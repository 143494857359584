<script>

import { mapActions, mapState ,mapMutations} from 'vuex';
import pageCode from "../../components/pageCode.vue";
export default {
data(){
  return {
    // 搜索的关键字
    searchKey:  '',
  }
},

methods:{

  ...mapMutations('goodsList',['setGoodsListdata']),

    // 调用防抖函数，执行逻辑
    handleFn(){
      // 调用防抖函数，执行逻辑
      this.antiShake()
    },

    // 防抖函数
    antiShake(){
        // 清除之前的定时器
        clearTimeout(this.timer)

        // 设置新的定时器
        this.timer=setTimeout(this.goSearch,500)
    },

  
  // 去搜素的按钮方法，去商品列表页面
  async  goSearch(){
      // 构造url字符串
    let url ='Home/GoodsSpu/showlist?searchKey=' + this.searchKey ;   
    let {data : res}= await this.$http.get(url)
    // console.log('齐鲁青未了');
    // console.log(res.data);

    if(res.code==200){
      // 给spuData赋值
    this.setGoodsListdata(res.data)
     // 保留不删除
      //如果在数goodsList本页面中，强制刷新本页面
      // window.location.reload();  
    }else{
      this.setGoodsListdata('')
      console.log('你查找的内容暂时还没有');
    }
    
        },

 },
 mounted(){

    //  初始化定时器
    this.timer=null
 },
 created(){
 this.searchKey=localStorage.getItem('searchKey')?
            localStorage.getItem('searchKey'):'挂画',
    // 初始化商品列表页面
   this.goSearch()

  }

}

</script>

<template>
  <!--/*header头部模块制作start*/-->
  <div class="allSearchbox">

      <!--/*logo模块制作*/-->
      <div class="logo">
          <div class="hanzi"> 三十次方</div>
          <div  class="pingying">SSCF</div>
      </div>


      <!-- /*search模块制作*/ -->
      <div class="searchbox">
        <span  class="cate">
          <select name="" id="">
            <option value="">宝贝</option>
            <option value="">高考志愿</option>
          </select>
        </span>
        <span class="inputSearch">
          <input type="search" name="" id="" placeholder="挂画" v-model="searchKey"/>
        </span>
        <span class="gotosearch"
               
                @click.stop="handleFn"
         
        >搜索</span>
    
      </div>



      <div  class="QRcode">
        <div class="imagetext">
          <span>下载三十次方</span>
          <img src="../../assets/images/ewm.jpg" alt="">
        </div>
       

      </div>

    </div>
</template>


<style  lang='less'  scoped>
.allSearchbox {
  display: flex;
  align-items: center;
  width: 100%;
  background: #f8f2f2;
  background: #e1e1e1;
  background: #ffffff;
  // background:blue;
  height: 120px;
  // logo样式
  .logo{
    flex:1;
    display:flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    text-align: center;
    
    .hanzi{
      font-size: 32px;
      font-weight: 700;
      color: #f74141;
    }
    .pingying{
      font-size: 24px;
      font-weight: 700;
      color: #f74141;
    }

  }

  // 搜索模块
  .searchbox{
    flex:4;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    line-height: 120px;
    background: #FFFFFF;
    border: 2px solid #f74141;
    border-radius: 20px;
    cursor: pointer;
    // 类别
    .cate{
      width: 60px;
      height: 40px;
      line-height: 40px;
      select{
        width: 100%;
        height: 60%;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        outline: none;
        border: none;
        border-right: 1px solid #eeeeee;
        padding-left: 10px;
        color: #333333;
      }
    }
    // 输入框
    .inputSearch{
      flex: 1;
      height: 100%;
      line-height: 36px;
      input{
        width: 100%;
        height: 90%;
        outline: none;
        border: none;
        
        padding-left: 20px;
      }
      
    }
    // 搜索按钮
    .gotosearch{
      width: 100px;
      height: 34px;
      background:#f74141;
      border-radius: 20px;
      color: #fff;
      line-height: 34px;
      text-align: center;
      margin: 2px;
      font-size: 18px;
      font-weight: 700;
    }


  }
  // 二维码
  .QRcode{
    flex:1;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    .imagetext{
      display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 100px;
    background: #ffffff;
      span{
        color: #f74141;
        font-size: 12px;
    }
    img{
      width: 80%;
      height: 80px;
      
    }
  }
   
  }


}
</style>