
// 这个必须有，在vuex模块中，即使全局引入过，也必须再引入axios，否则，成为了个体没有定义了
// 引入后，必须用axios,而不能用this.$http。
// 5、导入axios包
import axios from "axios";  

const pageCode={
    namespaced:true,
     state(){       //state里面数据不能用this调用，必须用state调用
         return {
            // 所要展示的页面的数据接口地址
            showPageDataApiUrl:'',
                
            // 页面展示的数据
            pageListData:[],

            // 总共有多少页面，即页面数量
            pageTotal: 1,
         }
     },
     getters:{},
     mutations:{
        // 设置所要展示的页面的数据接口地址
        setShowPageDataApiUrl(state,url){
            state.showPageDataApiUrl=url
          
        },
        
          // 设置页码页面全部数据
          setPageListData(state,value){  
            // if(state.pageListData===[]){
            //     state.pageListData = ''; 
            // }  
            state.pageListData = value;         
                         
         },

         // 设置页码页面全部总页码数据
          setPageTotal(state,value){
            state.pageTotal = value;              
        },
        
       
     },
     actions:{

           // 从数据库获取每个表格需要展示的数据函数
        async getPageListData(context,value) {
           
        // let url = "Admin/GoodsSpu/showlist?page_id=" + value + "&seller_id="; 
         let url =  value;         
        let {data : res} = await  axios.get(url);

        console.log('我是页码器');
        console.log(res);
        if(res.code == 200  && res.data.length !== 0){

         //再让列表页数据填充新数据
        context.commit('setPageListData', res.data)//这个地方，必须用context调用commit
        // 页码数据，总共有多少页
        context.commit('setPageTotal', res.pageCodeTotal)//这个地方，必须用contextcommit
            

            }
        
           
        },
        
     },
 
     modules:{}
 
 
 }
 export default pageCode