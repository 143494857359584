
<script>
export default {
  data(){
    return {
      user_name:localStorage.getItem('user_name')?localStorage.getItem('user_name'):'',
    }
  },

  methods:{
      // 退出
      exit(){
       let flag= confirm('你确认要退出三十次方商城吗？')
       console.log(flag);
       if(flag===true){
              // 删除token等待
              localStorage.removeItem('home_token')
              localStorage.removeItem('user_id')
              localStorage.removeItem('user_name')
              localStorage.removeItem('searchValue')
              this.$router.push('/login')

       }
      }

    }
  
};
</script>


<template>
  <div class="box">

  
  
  <section class="navbox">

    <ul class="left">
      <!-- <li class="welcome">{{user_name}}，三十次方欢迎您！</li> -->
      <li class="welcome">三十次方欢迎您！</li>
      <li class="login">
        <router-link to="/login" >登录</router-link>| 
        <router-link to="/register" style="color: #a22">免费注册</router-link>
      </li>
    </ul>

    <ul class="right">
      <li ><router-link to="/" class="arrow-icon">首页</router-link></li>
      <li><router-link to="/mine" class="arrow-icon">我的</router-link></li>
      <li><router-link to="/GoodsCart" class="arrow-icon">购物车</router-link></li>
      <li><router-link to="/goodsList" class="arrow-icon">会员</router-link></li>
      <li>
        <a href="https://www.sscf.top/wantuo" class="arrow-icon">卖家中心</a>
        <!-- <a href=" http://localhost:8000/" class="arrow-icon">卖家中心</a> -->
           
        </li>
      
      <li ><router-link to="/UserCollect" class="arrow-icon">收藏夹</router-link></li>
   
      <li ><router-link to="/contactme" class="arrow-icon">客户服务</router-link></li>
      <!-- <li ><router-link to="/test" class="arrow-icon">test</router-link></li> -->
      
      
      <li  class="arrow-icon"
        @click="exit"> <a href="javascript:;">退出登录</a>
        </li>
    </ul>


  </section>
</div>
</template>


<style  lang='less'  scoped>

.box{
  width: 100%;
  height: 31px;
  background: #F5F5F5;
  
}
.navbox {
  display: flex;
  width: 90%;
  margin: 0 auto;
  height: 31px;
  
  line-height: 31px;
  font-size: 12px;

  .left {
  flex: 4;
  padding-left: 30px;
  display: flex;
  justify-content: space-around;
  .welcome {
  flex:1;
    }
    .login {
      padding-right: 150px;
}  
}

}



.right {
  flex: 5;
  display: flex;
  justify-content: space-around;
  margin-right: 10px;
}

.right li {
  flex: 1;
}
a{
  text-decoration: none;
  color: #666666;
}
.arrow-icon{
  color:#666666;
}


</style>





















<!-- <template>
  <div> -->
    <!-- 导航栏 组件-->
    <!-- <nav> -->
    <!-- <ul class="nav">
      <li><router-link to="/" class="a">首页</router-link></li>
      <li><router-link to="/goodsList" class="a">商品分类</router-link></li>
      
      <li><router-link to="/GoodsCart" class="a">我的购物车</router-link></li>
      <li><router-link to="/order" class="a">我的订单</router-link></li>
      <li><router-link to="/data" class="a">我要付款</router-link></li>
      <li><router-link to="/test" class="a">test</router-link></li>
      <li><router-link to="/Video" class="a">视频展示</router-link></li>
      <li><router-link to="/Swiper" class="a">Swiper</router-link></li>
      <li><router-link to="/StudyOnLine" class="a">学习在线</router-link></li>
      <li><router-link to="/Http" class="a">云端数据交互</router-link></li>      
      <li>
        <router-link to="/login" class="a">登录</router-link>|
        <router-link to="/register" class="a">注册</router-link>|
        <router-link to="/address" class="a">地址</router-link>
      </li>
    </ul>
  
  </div>
</template>

<script>
export default {};
</script>

<style  lang="less"   scoped> -->
.nav {
  
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 45px;
  // background: -webkit-linear-gradient(left, orange, #faa);
  background: #F54343;
  line-height: 45px;
  text-align: center;
}
.nav > li {
  width: 20%;
  color: #fff;
}
.nav > li .a {
  color: #333333;
  color: #FFFFFF;
 
 
}
</style>