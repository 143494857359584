
import axios from 'axios'

// 定义一个商品列表模块
const mypublic = {
    // 模块命名空间
    namespaced: true,
    // 模块里面state必须是函数形式，
    state: {
        
           // 图片前缀
        //    PUBLIC:'http://localhost/E-shopping-interface/',
       
        
           PUBLIC:'https://www.sscf.top/E-shopping-interface/',
           
    },
    getters: {},
    mutations: {},
    actions: {},
    modules: {}
};

export default mypublic;