import Vue from 'vue'
import VueRouter from 'vue-router'

// Uncaught (in promise) NavigationDuplicated: Avoided redundant navigation to current location: "/goodsList".
// 在用vue-router 做单页应用的时候重复点击一个跳转的路由会出现报错
// 这个报错是重复路由引起的
// 只需在注册路由组建后使用下方重写路由就可以

// import Router from 'vue-router';
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


// import Vue from 'vue'
// import VueRouter from 'vue-router'

//核心代码
// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err)
// }

// Vue.use(VueRouter)

import top_nav from '../components/top_nav'
import Home from '../pages/Home'
import goodsList from '../pages/GoodsList'




Vue.use(VueRouter)

const routes = [
   // 导航
   {
    path: '/top_nav',
    name: 'top_nav',
    component: top_nav
  },
  // 首页
  {
    path: '/',
    name: 'Home',
    component: Home
  },
 

    // 空页
    {
      path: '/empty',
      name: 'empty',
      // component: Empty
      component:()=>import('../pages/Empty')
    },
  
 
  // 商品
  {
    path: '/GoodsList',
    name: 'GoodsList',
    // component:goodsList,
    component:()=>import('../pages/GoodsList'),
  },
  // 商品详情页A版
  {
    path: '/GoodsDetail_A',
    name: 'GoodsDetail_A)',
    // component:GoodsDetail_A
    component:()=>import('../pages/GoodsDetail_A')
  },
   // 商品详情页B版
  {
    path: '/GoodsDetail_B',
    name: 'GoodsDetail_B',
    // component:GoodsDetail_B
    component:()=>import('../pages/GoodsDetail_B')
  },
   // 商品详情页用户评价
  //  {
  //   path: '/allComment',
  //   name: 'allComment',
  //   // component:GoodsDetail_B
  //   component:()=>import('../pages/GoodsDetailPublic/allComment')
  // },
  // 用户
  {
    path: '/login',
    name: 'login',
    // component:login
    component:()=>import('../pages/Mypublic/login')
  },
 
   {
    path: '/register',
    name: 'register',
    // component:register
    component:()=>import('../pages/Mypublic/register')
  }, 
 
  {
    path: '/show_address',
    name: 'show_address',
    // component:show_address
    component:()=>import('../pages/Address/show_address')
  },
  {
    path: '/add_address',
    name: 'add_address',
    // component:add_address
    component:()=>import('../pages/Address/add_address')
  },
  {
    path: '/updata_address',
    name: 'updata_address',
    // component:updata_address
    component:()=>import('../pages/Address/updata_address')
  },
  // 购物车
  {
    path: '/GoodsCart',
    name: 'GoodsCart',
    // component:GoodsCart
    component:()=>import('../pages/GoodsCart')
  },

  // 订单
  {
    path: '/order',
    name: 'order',
    // component:order
    component:()=>import('../pages/Order')
  },
  {
    path: '/myOrder',
    name: '/myOrder',
    // component:myOrder
    component:()=>import('../pages/Mine/MyOrder')
  },

    // 支付
    {
      path: '/Pay',
      name: 'Pay',
      // component:Pay,
      component:()=>import('../pages/Pay')
    },
  
  {
    path: '/payforsuccess',
    name: 'payforsuccess',
    // component:payforsuccess,
    component:()=>import('../pages/Pay/payforsuccess')
  },

  // 支付
  {
    path: '/weixincode',
    name: 'weixincode',
    // component:Pay,
    component:()=>import('../pages/Pay/weixincode')
  },
  
  // 我的
  {
    path: '/mine',
    name: 'mine',
    // component:mine
    component:()=>import('../pages/Mine')
  },

  // 收藏夹
  {
    path: '/UserCollect',
    name: 'UserCollect',
    // component:UserCollect,
    component:()=>import('../pages/UserCollect'),
  },

  // 客户服务
  {
    path: '/contactme',
    name: 'contactme',
    // component:mine
    component:()=>import('../pages/Contactme')
  },

  // 关于我们
  {
    path: '/Abouteme',
    name: 'Abouteme',
    // component:mine
    component:()=>import('../pages/Abouteme.vue')
  },

   // test
   {
    path: '/test',
    name: 'test',
    // component:mine
    component:()=>import('../pages/Test')
  },

   // 网站底部
   {
    path: '/bottom',
    name: 'bottom',
    // component: bottom
    component:()=>import('../components/bottom')
  },
]

const router = new VueRouter({
  // mode: 'history',
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

// // 路由导航守卫
router.beforeEach((to, from, next) => {

    //  永久保存from.path路径，以便login页面使用，按from.path返回
    localStorage.setItem('home_from_path',from.path)
 

// 不需要登录，不需要token，可以访问的页面
let  router_arr = [
            '/login',
            '/register',
            '/',
            '/GoodsList',
            '/GoodsDetail_A',
            '/GoodsDetail_B',
            '/GoodsDetailPublic',
          ]

// 公开页面，不需要token
if(router_arr.includes(to.path) ){
     next()
     return

  }

//   // 需要token才可以登录的页面
//   // 取出本地token
let token = localStorage.getItem('home_token')

// // 判断是否token存在
if(token){
  console.log(token);
  next()
  return
}

// 无token，需去登录页面
  console.log('没有token');
  next('/login')

})

export default router
