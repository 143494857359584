
<script>
import { mapActions, mapState ,mapMutations} from 'vuex';
import pageCode    from "../../components/pageCode.vue";
export default {
  components:{ 
             pageCode,
            },
  computed:{
      // 图片前缀
      ...mapState('mypublic',['PUBLIC']),
    ...mapState('goodsList',['goodsListdata']),
  },

 
  methods: {

    ...mapMutations('GoodsDetail',[
      // 详情页公共数据set_Final_selected_sku置空
      'set_Final_selected_sku',
       // 给左侧主题图片leftTopicImageURL赋值
       'setleftTopicImageURL',
        // 设置详情页spu数据
       'set_DetailPage_SpuData',
         //1、 该spu展示模板的数据
        'set_show_template',
       
    ]),
    
    
    // 跳转到详情页
    gotoGoodsDetail(item) {

      // 首先，让详情页的公共数据final_selected_sku置空
        this.set_Final_selected_sku('')
    
      // 跳转到详情页之前，先给详情页数据spu_id赋值  setSpuId(state,value)
       // 永久化存储
       localStorage.setItem('home_spu_id',item.spu_id)

       // 将spu_title存入本地，后面sku_title到处要用
       localStorage.setItem('home_spu_title',item.spu_title)

       // 设置详情页spu数据
       this.set_DetailPage_SpuData(item)


        // 该spu展示模板的数据
       this.set_show_template(item.spu_templatetype)

      //  console.log('禅房花木深');
       // 跳转到详情页模板A
      if(item.spu_templatetype==0){this.$router.push("/GoodsDetail_A");} 
      // 跳转到详情页模板B
      if(item.spu_templatetype==1){this.$router.push("/GoodsDetail_B");}
        
    },
    
  },
 
}
</script>

<template>
  <div class="goodsListbox">
  <div class="content">
    <div class="nothing"  v-if="goodsListdata.lenth !== 0">
        你查找的内容现在没有
    </div>
    <div class="right"    v-else>
      <div
        class="goods"  
        v-for="item in goodsListdata"
        :key="item.spu_id"
        @click="gotoGoodsDetail(item)"
      >
        <div class="goodspic">
          <img
            :src="PUBLIC + item.spu_titleimage"
            :alt="item.spu_desc"
            :title="item.spu_name"
          />
        </div>
        <div class="price">￥{{item.spu_price}}</div>
        <div class="goodsdecript">{{item.spu_title}}</div>
        <div>{{item.spu_id}}</div>
      </div>
    </div>
   
  </div>
  <!-- 引进页面分页代码 -->
     <!-- <pageCode></pageCode> -->
    
</div>
</template>

<style lang="less"   scoped>
.content {
  display: flex;
  width: 90%;
  margin: 0 auto;
  background: #fff;
}

.content .left {
  flex: 1;
  background: #567;
  // background: #fff;
}

/* 父组件设置为flex布局 */

.content .right {
  flex: 3;
  // background: #ccc;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  
  margin-top: 3px;
  border-left: 10px solid #eee;
}
/* 商品模块 */

.content .right .goods {
 
  width: 20%;
 
  height: 350px;
  border-right: 15px solid #eee;
  border-bottom: 15px solid #eee;
  background: #fff;
 
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.content .right .goods .goodspic {
  flex: 6;
  //height: 160;
  overflow: hidden;
}

.content .right .goods .goodspic img {
  width: 100%;
  height: 100%;
 
}

.content .right .goods .price {
  flex: 1;
  height: 20px;
  font-size: 18px;
  font-weight: bold;
  color: #e66;
}

.content .right .goods .goodsdecript {
  flex: 1;
  height: 40px;
  font-size: 14px;
  text-align: start;
  padding-left: 5px;
}

.nothing{
  width: 100%;
  height: 100vh;
  margin: auto;
  text-align: center;
  font-size: 20px;

}
</style>